import { useEffect } from 'react';
import { mainSliceActions } from '../store/mainSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import axios from 'axios';
import qs from 'qs';
import pako from 'pako';


export const useFetchHtml = () => {
  const dispatch = useAppDispatch();
  const { currentId, isModal } = useAppSelector(state => state.main);

  useEffect(() => {
    const fetchHtml = async () => {
      dispatch(mainSliceActions.setIsLoadingHtml(true));
      try {
        const WS = '/GetArticleHtml';
        const URL =
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_LOCAL_DEV_URL
            : process.env.REACT_APP_PROD_URL;
        const formData = qs.stringify({
          articleId: currentId
        });
        // const data = {
        //   articleId: currentId
        // };
        const response = await axios.post(
          URL + WS,
          formData,
        );

        const base64EncodedData = response.data.data;
        const binaryString = atob(base64EncodedData);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const decompressedData = pako.inflate(bytes);
        const decoder = new TextDecoder('utf-16');
        const decompressedString = decoder.decode(decompressedData);
        // console.log(decompressedString);

        // console.log('Decompressed string:', decompressedString);
        const wrappedContent = `<div class="content-wrapper">${decompressedString}</div>`;
        dispatch(mainSliceActions.setHtmlContent(wrappedContent));

      } catch (error) {
        // console.error('Error fetching HTML:', error);
        dispatch(mainSliceActions.setIsHtmlError(true));
      } finally {
        dispatch(mainSliceActions.setIsLoadingHtml(false));
      }
    };

    if (isModal) {
      fetchHtml();
    }
  }, [dispatch, isModal, currentId]);

};
