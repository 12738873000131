import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { mainSliceActions } from '../../store/mainSlice';
import styles from './style';
import CircularProgress from '@mui/material/CircularProgress';
import Tag from '../Table/Tag'


const Article: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isModal,
    currentArticle,
    htmlContent,
    currentId,
    data,
    currentPage,
    itemsPerPage,
    hasMore,
    isLoadingHtml,
    isHtmlError,
    noDataHtmlMsg,
    previousPageData,
    allData,
  } = useAppSelector((state) => state.main);

  useEffect(() => {
    if (currentId && allData[currentPage] && allData[currentPage].length > 0) {
      const current = allData[currentPage].find((obj) => obj.ArticleID === currentId);
      if (current) {
        dispatch(mainSliceActions.setCurrentArticle(current));
      } else {
        const previous = previousPageData.find((obj) => obj.ArticleID === currentId);
        if (previous) {
          dispatch(mainSliceActions.setCurrentArticle(previous));
        }
      }
    }
  }, [currentId, allData, currentPage, previousPageData, dispatch]);

  const handleClose = () => {
    dispatch(mainSliceActions.setIsModal(false));
  };

  const goToPrevious = async () => {
    if (!currentArticle || allData[currentPage].length === 0) {
      console.log('Current article not found in data or data is empty.');
      return;
    }
    const currentIndex = allData[currentPage].findIndex((item) => item.ArticleID === currentArticle.ArticleID);

    if (currentIndex > 0) {
      const previousId = allData[currentPage][currentIndex - 1].ArticleID;
      dispatch(mainSliceActions.setCurrentId(previousId));
      dispatch(mainSliceActions.setCurrentArticle(allData[currentPage][currentIndex - 1]));
    } else if (currentIndex === 0 && currentPage > 1) {
      const previousPageIndex = currentPage - 1;
      if (allData[previousPageIndex] && allData[previousPageIndex].length > 0) {
        const previousId = allData[previousPageIndex][allData[previousPageIndex].length - 1].ArticleID;
        dispatch(mainSliceActions.setCurrentId(previousId));
        dispatch(mainSliceActions.setCurrentArticle(allData[previousPageIndex][allData[previousPageIndex].length - 1]));
        dispatch(mainSliceActions.setCurrentPage(previousPageIndex));
      } else {
        console.log('Previous page data is empty or not yet loaded.');
      }
    } else {
      console.log('Already on the first page or current article not found in data.');
    }
  };

  const goToNext = () => {
    if (!currentArticle || data.length === 0) return;
    const currentIndex = data.findIndex((item) => item.ArticleID === currentArticle.ArticleID);

    if (currentIndex < data.length - 1) {
      // Navigate to the next article within the current page
      const nextId = data[currentIndex + 1].ArticleID;
      // console.log('current ',currentArticle,'next ', nextId, data, currentIndex);
      
      dispatch(mainSliceActions.setCurrentId(nextId));
      // Optionally update currentPage if necessary
      if (currentIndex + 1 === currentPage * itemsPerPage) {
        dispatch(mainSliceActions.setCurrentPage(currentPage + 1));
      }
    } else if (hasMore) {
      // Move to the next page if on the last item of the current page and hasMore is true
      const nextPage = currentPage + 1;
      dispatch(mainSliceActions.setCurrentPage(nextPage));
    } else {
      console.log('End of pagination reached');
    }
  };

const tagsArray = (currentArticle?.Tags ?? '').split(';').map(tag => tag.trim());
  
  return (
    //  PaperProps={{ style: { maxHeight: '90vh', minHeight: '60vh', height: '85vh',width: '85vw', overflowY: 'initial' } }}
    <styles.StyledDialog open={isModal} onClose={handleClose} fullWidth maxWidth="xl">
      <styles.Header>
        {currentArticle && (
          <styles.HeaderContainer>
            <styles.HeaderTitle variant="h4">{t('versionDetails')}</styles.HeaderTitle> 
            <styles.SubHeader>
              <styles.System variant="subtitle1">
                <styles.SystemImage src={`./${currentArticle?.MainClassificationName}.png`} alt=''/>
                <styles.SystemText>
                  {currentArticle?.MainClassificationName} 
                </styles.SystemText>
              </styles.System>
              <styles.VerticalLine />
              <styles.Date variant="subtitle1">
                <styles.DateText>
                  {currentArticle?.RelevantFromDate && currentArticle?.RelevantFromDate?.split('-').reverse().join('.')}
                </styles.DateText>
              </styles.Date>
              <styles.VerticalLine />
              <styles.Version variant="subtitle1">
                <styles.VersionText>
                {t('ArticleHeader')} {currentArticle?.ArticleHeader}
                </styles.VersionText>
              </styles.Version>
            </styles.SubHeader>
            <Tag tags={tagsArray}/>
          </styles.HeaderContainer>
        )}

        <styles.StyledActions>
          <styles.StyledButton onClick={handleClose}>
            <styles.StyledCloseIcon/>
          </styles.StyledButton>
        </styles.StyledActions>

      </styles.Header>
    <styles.ScrollViewWrapper>
      <styles.ScrollView>
        {isLoadingHtml ? 
          <styles.LoadingContainer>
            <CircularProgress style={{width: '55px', height: '55px'}}/> 
          </styles.LoadingContainer>
        : noDataHtmlMsg ?
          <div>{t("noSearchData")}</div>
        : isHtmlError ? 
        <div>{t("errorMsg")}</div>
        :
        <styles.StyledDialogContent style={{}}>
          <styles.Article dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </styles.StyledDialogContent>
        }
      </styles.ScrollView>
    </styles.ScrollViewWrapper>

    <styles.FooterContainer>
      <styles.Footer>
        <styles.PreviousBtn onClick={goToPrevious} disabled={!currentArticle || (currentPage === 1 && data.findIndex((item) => item.ArticleID === currentArticle.ArticleID) === 0)}>
          <styles.ArrowRight />
          {t('toPreviousVersion')}
        </styles.PreviousBtn>
        <styles.NextBtn onClick={goToNext}
        disabled={
        !currentArticle ||
        (data.length > 0 && data.findIndex((item) => item.ArticleID === currentArticle.ArticleID) === data.length - 1 && !hasMore)}>
          {t('toNextVersion')}
          <styles.ArrowLeft />
        </styles.NextBtn>
      </styles.Footer>
    </styles.FooterContainer>
    </styles.StyledDialog>
  );
};

export default Article;
