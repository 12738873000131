import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { TableRow, mainSliceActions } from 'src/store/mainSlice';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import styles from './style';
import CircularProgress from '@mui/material/CircularProgress';
import SortArrowHollow from 'src/images/svg/SortArrowHollow';
import Header from './Header';
import { useIsRTL } from 'src/hooks/useIsRTL';
import PaginationItem from '@mui/material/PaginationItem';
import { useFetchHtml } from 'src/hooks/useFetchHtml';
import { useResponsiveItemsPerPage } from 'src/hooks/useResponsiveItemsPerPage ';
import AllTags from './AllTags';
import { useFetchArticles } from '../../hooks/useFetchArticles';


const Table: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isRTL = useIsRTL();
  useFetchHtml();
  const { hasMore, data, isLoading, currentPage, sortedBy, sortedOrder, noDataMsg, isError, offset, itemsPerPage, systemId, searchInput, sort, isAsc, articleTagId } = useAppSelector(state => state.main);
  const columns: (keyof TableRow)[] = ['RelevantFromDate', 'MainClassificationName', 'ArticleHeader', 'Tags'];
  const rowsPerPage = useResponsiveItemsPerPage();
  useResponsiveItemsPerPage();

  const articlesPayload = { row: offset, maxRowInPage: itemsPerPage, MainClassificationID: systemId, sort, isAsc, searchKey: searchInput, TagID: articleTagId };
  const { allPagesData, tableData, isMorePages } = useFetchArticles(articlesPayload);

    useEffect(() => {
        dispatch(mainSliceActions.updatePagination());
    }, [dispatch, currentPage, tableData, rowsPerPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    console.log(hasMore, tableData.length > rowsPerPage, isMorePages);
    dispatch(mainSliceActions.setCurrentPage(page));
  };

  const moveToHtml = (ArticleID: number, index: number) => {
    console.log(ArticleID);
    dispatch(mainSliceActions.setCurrentId(ArticleID));
    dispatch(mainSliceActions.setIsModal(true));
    dispatch(mainSliceActions.setIndex(index));
  }

  const handleSort = (column: string) => {
    dispatch(mainSliceActions.setSortedBy(column));
    dispatch(mainSliceActions.setSort(column));
    dispatch(mainSliceActions.setSortedOrder());
    dispatch(mainSliceActions.setIsAsc());
    dispatch(mainSliceActions.setCurrentPage(1));
  };
  
  return (
    <styles.BodyWrapper>
    <styles.TableWrapper> 
      <Header />

      {isLoading ? 
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh'}}>
      <CircularProgress style={{width: '55px', height: '55px'}}/> 
    </div>
      : noDataMsg ? 
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh'}}>{t("noSearchData")}</div>
      : isError ?
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20vh'}}>{t("errorMsg")}</div>
      :
      <styles.StyledTable>
        <thead>
          <styles.StyledTableRow $isHeader>
            {columns?.map((column, index) => (
              <styles.TableColumn key={index}>
                <div 
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> handleSort(column)}
                >
                {t(column)}
                {sortedBy === column && sortedOrder === 'up' && column !== 'Tags' ? 
                  <styles.ArrowUp style={{ height: '16px'}}/> 
                : sortedBy === column && sortedOrder === 'down' && column !== 'Tags' ? 
                <styles.ArrowDown style={{ height: '16px'}} /> 
                :  column === 'Tags' ? null : <SortArrowHollow/> }
              </div>
              </styles.TableColumn>
            ))}
          </styles.StyledTableRow>
        </thead>
        <tbody>
          {tableData.slice(0, rowsPerPage)?.map((row, rowIndex) => (
            <styles.StyledTableRow key={rowIndex}>
            {columns?.map((column, colIndex) => {              
              let reversedDate;
              let allTags;
              if(column === "RelevantFromDate") {
              reversedDate = row[column]?.split('-').reverse().join('.');
              }
              if(column === "Tags") {
                // allTags = row[column]
                allTags = row[column]?.trim().split(';');
              }
              type Tags = {
                tags?: string[]| undefined;
              };
                return(
                  <styles.TableData 
                    key={colIndex} 
                    onClick={() => moveToHtml(row.ArticleID, rowIndex) 
                  }>
                  {column === 'RelevantFromDate' && <img src={`./${row['MainClassificationName']}.png`} alt='' style={{width: '33px', borderRadius: '50%', verticalAlign: 'middle'}}/>}
                  <span style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '5px' }}>
                    {column === "RelevantFromDate" ? reversedDate : column === "Tags" ? 
                    // allTags
                    <AllTags tags={allTags}/> 
                    : row[column]}
                  </span>
                  </styles.TableData>
                )}
              )}
            </styles.StyledTableRow>
          )
          )}
        </tbody>
      </styles.StyledTable>
      }
      <styles.PaginationContainer>
      <Stack spacing={2}>
          <Pagination
            count={tableData?.length > rowsPerPage ? currentPage + 1 : currentPage}
            page={currentPage}
            onChange={handlePageChange}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
            style={{
              direction: isRTL ? 'rtl' : 'ltr',
              alignSelf: 'center',
              bottom: '12px',
            }}
            renderItem={(item) => {      
              const shouldDisplay =
                item.page !== null &&
                (item.page === currentPage ||
                 item.page === currentPage - 1 ||
                 item.page === currentPage + 1);
      
              if (shouldDisplay) {
                return (
                  <PaginationItem
                    {...item}
                    style={{
                      maxHeight: '24px',
                      borderRadius: '4px',
                      backgroundColor: item.selected ? '#2196F3' : 'white',
                      color: item.selected ? 'white' : '#494949',
                      border: item.selected ? '1px solid #2196F3' : '1px solid #EEEEEE',
                    }}
                  />
                );
              } else {
                return null;
              }
            }}
            disabled={isLoading}
          />
        </Stack>
      </styles.PaginationContainer>
    </styles.TableWrapper>
    </styles.BodyWrapper>
  );
};

export default Table;
