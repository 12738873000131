import React from 'react';
import { t } from 'i18next';
import styles from './styles';

const Header: React.FC = () => {

    return (
        <styles.Header>
            <styles.Headliner 
            variant="h4">
                {t('headline')}
            </styles.Headliner>
            <YitLogo />
        </styles.Header>
    )
}

const YitLogo: React.FC<{ style?: React.CSSProperties }> = ({ style = {} }) => {
  return <styles.LogoContainer style={style} />;
};

export default Header;
