import React, { useEffect, useState } from 'react';
import styles from './style';

type Tags = {
  tags?: string[] | undefined;
};

const AllTags: React.FC<Tags> = ({ tags }) => {
  const [displayedTags, setDisplayedTags] = useState<string[] | undefined>(tags);
  const [hiddenTags, setHiddenTags] = useState<string[] | undefined>([]);
  const [isHidden, setIsHidden] = useState(false);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const charsLimit = 10;
  let tagsLimit = 5;
  
  if (screenWidth > 1712) {
    tagsLimit = 10;
  } else if (screenWidth > 1460) {
    tagsLimit = 8;
  } else if (screenWidth > 1040) {
    tagsLimit = 4;
  } else if (screenWidth > 815) {
    tagsLimit = 2;
  } else if (screenWidth > 550) {
    tagsLimit = 1;
  } else if (screenWidth > 350) {
    tagsLimit = 1;
  } else if (screenWidth > 0) {
    tagsLimit = 1;
  }

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setScreenWidth(newScreenWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (tags && tags.length > tagsLimit) {
      const displayed = tags.slice(0, tagsLimit);
      const hiddenCount = `${tags.length - tagsLimit}+`;
      displayed.unshift(hiddenCount);
      setDisplayedTags(displayed);
      setHiddenTags(tags.slice(tagsLimit));
      setIsHidden(true);
    } else {
      setDisplayedTags(tags ?? []);
      setHiddenTags([]);
      setIsHidden(false);
    }
  }, [tags, tagsLimit]);

  return (
    <div>
      {Array.isArray(tags) ? (
        <styles.TagsContainer>
          {(displayedTags ?? []).map((tag, i) => {
            const isFirst = i === 0 && isHidden;
            const isLongTag = tag.length > charsLimit;
            const displayTag = isLongTag ? `${tag.slice(0, charsLimit)}...` : tag;
            const tooltipText = isFirst ? hiddenTags?.join(', ') : tag;

            return (
              <styles.TagItem
              key={i}
              $isFirst={isFirst}
              $isHidden={isHidden}
              data-tooltip={tooltipText}
              >
                {displayTag}
              </styles.TagItem>
            );
          })}
        </styles.TagsContainer>
      ) : (
        <span>{tags}</span>
      )}
    </div>
  );
};

export default AllTags;