import styled from 'styled-components';

const InfoFooterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  box-shadow: 0px -2px 10px 0px #0000001A;
  height: 3%;
`;
  // height: 32px;  flex: 0.5;


const FooterLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-right: 0.5vw;

  &:first-child {
  margin-right: 0;
  }
`;

const FooterLink = styled.div`
  display: flex;
  gap: 20px;
`;

const Link = styled.a`
  font-size: 12px;
  color: #494949;
  font-family: Rubik;
  font-weight: 400;

  @media (max-width: 539px) {
    font-size: 9px;
  }

  @media (max-height: 550px) {
    font-size: 9px;
  }
`;

const FooterVersionWrapper = styled.div`
    display: flex;
    justify-content: end;
    padding: 0 10px;
    width: 200px;
`;

const styles = {
    InfoFooterDiv,
    FooterLinkWrapper,
    FooterLink,
    Link,
    FooterVersionWrapper,
};

export default styles;