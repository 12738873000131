import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';

const StyledTable = styled.table`
  width: 100%;
  box-sizing: border-box;
  border-spacing: 0 8px;
  table-layout: fixed;
`;

const TableData = styled.td`
  border-top: 1px solid #E4E4E4;
  border-bottom: 1px solid #E4E4E4;
  padding: 8px;
  font-family: Rubik;
  font-weight: 400;
  color: #494949;
  font-size: 14px;
  position: relative;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:first-child {
    border-right: 1px solid #E4E4E4;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:last-child {
    border-left: 1px solid #E4E4E4;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;
    // overflow: hidden;

    const TableColumn = styled.th`
    padding: 8px;
    text-align: start;
    background-color: #E6F6FE;
    font-family: Rubik;
    font-weight: 500;
    color: #233341;
    font-size: 16px;
  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 20%;
  
    &:first-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  
    &:last-child {
      width: 40%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  
    @media (max-width: 768px) {
      font-size: 14px;
    }
  
    @media (max-width: 480px) {
      font-size: 12px;
    }
  `;
    // max-width: 20vw;
    // min-width: 20vw;
    const StyledTableRow = styled.tr<{ $isHeader?: boolean }>`
    border-top: 1px solid #E4E4E4;
    border-bottom: 1px solid #E4E4E4;
    padding: 8px;
    height: 42px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-radius 0.2s ease-in-out;
  
    ${(props) =>
      !props.$isHeader &&
      `
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1);
        position: relative;
        border-radius: 8px;
      }
    `}
  `;
//background-color: #E6F6FE;        z-index: 1;


const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  position: absolute;
  bottom: 1%;

  @media (max-width: 768px) {
    margin-top: 8px;
  }

  @media (max-width: 480px) {
    margin-top: 4px;
  }
`;
  // 

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const tableScrollView = styled.div`
display: block;
max-height: 60vh;

width: 100%;
`;
// overflow-y: auto;
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F6F6F6E5;
  width: 100%;
  height: 92%;
  overflow-x: auto;
`;
    // flex: 20;



    const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0px 0px 20px 0px #0000001A;
    height: 85%;
    width: 95%;
    padding: 30px;
    box-sizing: border-box;
    position: relative;

  
    @media (max-width: 768px) {
      height: 90%;
      width: 100%;
      padding: 20px;
    }
  
    @media (max-width: 480px) {
      height: 95%;
      width: 100%;
      padding: 10px;
    }
  `;
  
    // overflow-x: auto;
    // overflow-y: hidden;
// margin: 20px;
// padding: 20px;

const FilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    z-index: 9999;
`;
const StyledSearchBtn = styled.button`
    height: 38px;
    width: 80px;
    background-color: #2196F3;
    color: white;
    border-radius: 4px;
    border: none;
    font-size: 18px;
    margin: 3px;
    font-family: Rubik;
    font-weight: 400;
`;
//     width: 272px;

const CustomTextField = styled(TextField)`
  && {
    width: 272px;
    .MuiOutlinedInput-root {
      height: 38px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      margin: 3px;
      border-color: #B4BEC9;
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      color: #494949;
    }

    .MuiInputLabel-outlined {
      border-color: #B4BEC9;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      color: #C4C4C4;
      right: 25px;
      left: auto;
      top: 3px;
      transform: translate(14px, 9px) scale(1);
      transition: all 0.2s ease-in-out;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 4px;
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        notched: false;
      }
    }

    fieldset legend {
      text-align: right;
    }
  }
`;

const SearchWrapper = styled.div`
  position: relative;
`;

const ArrowUp = styled(SwitchLeftIcon)`
  transform: rotate(270deg);
`;

const ArrowDown = styled(SwitchLeftIcon)`
  transform: rotate(90deg);
`;

const DropdownContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  top: 38px;
  background: aliceblue;
  z-index: 99;
`;

const DropdownList = styled.div`
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
`;

const DropdownItem = styled.div`
    padding: 15px;
`;

const DropdownNoItem = styled.div`
    padding: 30px;
`;

// Tags

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
`;

interface TagItemProps {
  $isFirst: boolean;
  $isHidden: boolean;
}
    // justify-content: center;

    const TagItem = styled.span<TagItemProps>`
    position: relative;
    border-radius: 18px;
    background: ${({ $isFirst, $isHidden }) => ($isFirst && $isHidden ? 'rgb(73, 73, 73)' : '#E6F6FE')};
    color: ${({ $isFirst, $isHidden }) => ($isFirst && $isHidden ? 'white' : 'black')};
    padding: 4px;
    margin-left: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px #e7f6fd;
  
    &:hover {
      ${({ $isFirst, $isHidden }) =>
        $isFirst &&
        $isHidden &&
        `
        background: rgb(63, 63, 63);
      `}
    }
  
    &:hover::after {
      content: attr(data-tooltip);
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.75);
      color: white;
      padding: 4px;
      border-radius: 5px;
      white-space: nowrap;
      z-index: 99999;
    }
  `;

  const SingleTagItem = styled.span<TagItemProps>`
  position: relative;
  border-radius: 18px;
  background: ${({ $isFirst, $isHidden }) => ($isFirst && $isHidden ? 'rgb(73, 73, 73)' : '#E6F6FE')};
  color: ${({ $isFirst, $isHidden }) => ($isFirst && $isHidden ? 'white' : 'black')};
  padding: 4px;
  margin-left: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px #e7f6fd;

  &:hover {
    ${({ $isFirst, $isHidden }) =>
      $isFirst &&
      $isHidden &&
      `
      background: rgb(63, 63, 63);
    `}
  }

  &[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-100%);
    background: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 4px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 99999;
  }

  @media screen and (min-width: 3744px) {
    padding: 5px;
    margin-left: 15px;
  }
  @media screen and (min-height: 1848px) {
    padding: 5px;
    margin-left: 15px;
  }
  @media (max-height: 740px) {
  }

  @media (max-height: 600px) {
    padding: 1px;
    margin-left: 4px;
  }

  @media (max-width: 539px) {
    padding: 2px;
    margin-left: 5px;
  }
`;


const SingleTagContainer = styled.span`
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 12px;

  @media screen and (min-width: 3744px) {
      font-size: 15px;
  }
  @media screen and (min-height: 1848px) {
      font-size: 15px;
  }
    @media (max-height: 740px) {
      font-size: 6px;
      color: red;    
    }
    @media (max-height: 530px) {
      font-size: 6px;
      color: red;    
    }
    @media (max-width: 539px) {
      font-size: 8px;
    }
`;

const styles = {
    tableScrollView,
    TableContainer,
    StyledTable,
    TableData,
    TableColumn,
    StyledTableRow,
    PaginationContainer,
    BodyWrapper,
    TableWrapper,
    FilterWrapper,
    StyledSearchBtn,
    CustomTextField,
    SearchWrapper,
    ArrowDown,
    ArrowUp,
    DropdownContainer,
    DropdownList,
    DropdownItem,
    DropdownNoItem,
    // Tags
    TagsContainer,
    TagItem,
    SingleTagItem,
    SingleTagContainer,
};

export default styles;
