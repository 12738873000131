import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LangKeys } from './store/mainSlice';

const resources = {
  he: {
    translation: {
      clientVersion: "גרסת קליינט",
      serverVersion: "גרסת שרת",
      dropHeader: "תיעוד גרסאות",
      headline: "תיעוד גרסאות מוצרים",
      allSystems: "כל המערכות",
      versionDetails: "פרטי גירסה",
      systemName: "שם המערכת",
      MainClassificationName: "מערכת",
      search: "חיפוש...",
      searchBtn: "חיפוש",
      RelevantFromDate: "תאריך",
      ArticleHeader: "גרסה",
      Tags: "תגיות",
      Content: "תוכן",
      supportAndContact: 'יצירת קשר ותמיכה',
      whoAreWe: 'מי אנחנו',
      moreProducts: "עוד מוצרים",
      toNextVersion: "לגרסה הבאה",
      toPreviousVersion: "לגרסה הקודמת",
      noSearchData: "לא קיימות רשומות בחתך שנבחר",
      errorMsg: "מצטערים, קרתה שגיאה!",
      noTagsSuggesstions: "לא קיימות הצעות מתאימות!",
    },
  },
  en: {
    translation: {
      clientVersion: "client version",
      serverVersion: "server version",
      dropHeader: "versions record",
      headline: "versions record and products",
      allSystems: "all systems",
      versionDetails: "version Details",
      systemName: "system name",
      MainClassificationName: "system",
      search: "...search",
      searchBtn: "search",
      RelevantFromDate: "date",
      ArticleHeader: "version",
      Tags: "tags",
      Content: "Content",
      supportAndContact: "support and contact",
      whoAreWe: 'who are We',
      moreProducts: "more products",
      toNextVersion: "next version",
      toPreviousVersion: "previous version",
      noSearchData: "There are no records in the selected section",
      errorMsg: "",
      noTagsSuggesstions: "No suggestions available!",
    },
  },
  ar: {
    translation: {
      clientVersion: "גרסת קליינט",
      serverVersion: "גרסת שרת",
      dropHeader: "תיעוד גרסאות",
      headline: "תיעוד גרסאות מוצרים",
      allSystems: "כל הגרסאות",
      versionDetails: "تفاصيل الإصدار",
      systemName: "اسم النظام",
      MainClassificationName: "نظام",
      search: "...بحث",
      searchBtn: "بحث",
      RelevantFromDate: "تاريخ",
      ArticleHeader: "الإصدار",
      Tags: "tags",
      Content: "محتوى",
      supportAndContact: "الدعم والاتصال",
      whoAreWe: 'من نحن',
      moreProducts: "المزيد من المنتجات",
      toNextVersion: "الإصدار التالي",
      toPreviousVersion: "إصدار سابق",
      noSearchData: "ما فيش أي سجلات في القسم المحدد",
      errorMsg: "",
      noTagsSuggesstions: "לא קיימות הצעות מתאימות!",
    },
  },
  ru: {
    translation: {
      clientVersion: "גרסת קליינט",
      serverVersion: "גרסת שרת",
      dropHeader: "תיעוד גרסאות",
      headline: "תיעוד גרסאות מוצרים",
      allSystems: "כל הגרסאות",
      versionDetails: "Сведения о версии",
      systemName: "Имя системы",
      MainClassificationName: "система",
      search: "...поиск",
      searchBtn: "поиск",
      RelevantFromDate: "дата",
      ArticleHeader: "версия",
      Tags: "tags",
      Content: "содержание",
      supportAndContact: "поддержка и контакты",
      whoAreWe: 'кто мы',
      moreProducts: "Больше продуктов",
      toNextVersion: "следующая версия",
      toPreviousVersion: "Предыдущая версия",
      noSearchData: "В выбранном разделе нет записей",
      errorMsg: "",
      noTagsSuggesstions: "לא קיימות הצעות מתאימות!",
    },
  },
};
export const i18nLanguages = Object.keys(resources) as LangKeys[];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'he',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
