import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { useIsRTL } from 'src/hooks/useIsRTL';
import styled from 'styled-components';
import yitLogo from '../../images/yitLogo.png';
import styles from './styles';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const isRTL = useIsRTL();

  return (
    <styles.InfoFooterDiv>
      <div style={{display: 'flex'}}>

        <styles.FooterLinkWrapper>
          <YitLogo />
          <styles.FooterLink>
            <styles.Link
              href="https://www.y-it.co.il/%d7%a7%d7%a6%d7%aa-%d7%a2%d7%9c%d7%99%d7%a0%d7%95/"
              target="_blank"
              rel="noreferrer"
              style={{color: '#494949'}}
            >
              {t('whoAreWe')}
            </styles.Link>
          </styles.FooterLink>
        </styles.FooterLinkWrapper>


        <styles.FooterLinkWrapper>
          <styles.FooterLink>
            <styles.Link
              href="https://www.y-it.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8"
              target="_blank"
              rel="noreferrer"
            >
              {t('supportAndContact')}
            </styles.Link>
          </styles.FooterLink>
        </styles.FooterLinkWrapper>

        <styles.FooterLinkWrapper>
          <styles.FooterLink>
            <styles.Link
              href="https://www.y-it.co.il/products/"
              target="_blank"
              rel="noreferrer"
            >
              {t('moreProducts')}
            </styles.Link>
          </styles.FooterLink>
        </styles.FooterLinkWrapper>
      </div>

      <styles.FooterVersionWrapper>
        <p
          style={{
            textAlign: isRTL ? 'left' : 'right',
            fontSize: '12px',
            color: '#494949',
            fontFamily: 'Rubik',
            fontWeight: 400,
            padding: 0,
            margin: 0
          }}
        >
          {t("clientVersion")} {process.env.REACT_APP_VERSION_NUMBER}
        </p>
        {/* <p
          style={{
            textAlign: isRTL ? 'left' : 'right',
            fontSize: '12px',
            color: '#494949',
            fontFamily: 'Rubik',
            fontWeight: 400,
            padding: 0,
            margin: 0
          }}
        >
         {t("serverVersion")} {process.env.REACT_APP_VER}
        </p> */}
      </styles.FooterVersionWrapper>

    </styles.InfoFooterDiv>
  );
};


export const YitLogo: React.FC<{
  style?: React.CSSProperties;
}> = ({ style = {} }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${yitLogo})`,
        width: '50px',
        height: '20px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...style,
      }}
    />
  );
};


export default Footer;
