import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components, OptionProps, ActionMeta } from 'react-select';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { mainSliceActions } from '../../store/mainSlice';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import styles from './style';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [userInput, setUserInput] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [isSelectedTag, setIsSelectedTag] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedOption, setSelectedOption] = useState<OptionType | OptionType[] | null>(null);


  const { allSystems, suggestions, tags } = useAppSelector(state => state.main);

  interface OptionType {
    label: string;
    value: string;
    id: number;
    icon: string;
  }

  const handleSelect = (
    selectedOption: OptionType | OptionType[] | null,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (selectedOption && !Array.isArray(selectedOption)) {
      setSelectedOption(selectedOption);
      // Single value selection
      dispatch(mainSliceActions.setIsSearch(true));
      dispatch(mainSliceActions.setSystemId(selectedOption.id));
      dispatch(mainSliceActions.setSort('RELEVANTFROMDATE'));
      dispatch(mainSliceActions.setSortedBy('RelevantFromDate'));
      dispatch(mainSliceActions.setSortedOrder('up'));
      dispatch(mainSliceActions.setIsAsc(0));
      dispatch(mainSliceActions.setCurrentPage(1));
      dispatch(mainSliceActions.setSearchInput(''));
      dispatch(mainSliceActions.setArticleTagId(null));
      dispatch(mainSliceActions.setTagsID(''));
      setUserInput('');
    } else if (Array.isArray(selectedOption) && selectedOption.length > 0) {
      // Multi-value selection (handle as needed)
      // console.log('Multi-value selection:', selectedOption);
    }
  };

  const handleSearch = async () => {
    dispatch(mainSliceActions.setIsSearch(true));
    dispatch(mainSliceActions.setCurrentPage(1));
    dispatch(mainSliceActions.setArticleTagId(undefined));
    setFilteredSuggestions([]);
    dispatch(mainSliceActions.setSystemId(0));
    setSelectedOption(allSystems[0]);
  };

  const handleSearchInput = (value: string) => {
    setUserInput(value);
    setIsSelectedTag(false);
    const unLinked = suggestions?.filter(
      (suggestion) =>
        // suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1 // all tags who include this value
        suggestion.toLowerCase().startsWith(value.toLowerCase()) // all tags who start this value
    );
    setFilteredSuggestions(unLinked);
    dispatch(mainSliceActions.setSearchInput(value));
  };

  const Option = (props: OptionProps<OptionType>) => {
    return (
      <components.Option {...props}>
        {props.data.label !== t('allSystems') &&
          <img
            src={props.data.icon}
            alt={''}
            style={{ width: 20, height: 20, verticalAlign: 'middle' }}
          />
        }
        <span style={{
          display: 'inline-block', verticalAlign: 'middle',
          marginRight: props.data.label.includes(t('allSystems')) ? '0px' : '15px'
        }}>
          {props.data.label}
        </span>
      </components.Option>
    );
  };

  const handleSelectSuggestion = (suggestion: string) => {
    let selected = tags.find((item) => item.Name === suggestion);
    // console.log(`${selected?.TagID}`);
    
    dispatch(mainSliceActions.setTagsID(`${selected?.TagID}`));
    // console.log(tagsID);
    
    dispatch(mainSliceActions.setArticleTagId(selected?.TagID));
    setSelectedTag(suggestion);
    setIsSelectedTag(true);
    setUserInput(suggestion);
    setHighlightedIndex(-1); // Reset highlighted index
    dispatch(mainSliceActions.setSearchInput(''));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if(userInput !== '') {
        if(highlightedIndex >= 0) {
            handleSelectSuggestion(filteredSuggestions[highlightedIndex]);
        } else {
        const tagIds = filteredSuggestions.map(suggestion => {
            const tag = tags.find(item => item.Name === suggestion);
            return tag ? tag.TagID : null;
        }).filter(tagId => tagId !== null);
        dispatch(mainSliceActions.setTagsID(tagIds.join(',')));          
        }
       } else {
        dispatch(mainSliceActions.setTagsID(''));
        setFilteredSuggestions([]);
        dispatch(mainSliceActions.setArticleTagId(undefined));
      } 
        setSelectedOption(allSystems[0]);
        dispatch(mainSliceActions.setIsSearch(true));
        dispatch(mainSliceActions.setCurrentPage(1));
        setIsSelectedTag(true);
        dispatch(mainSliceActions.setSystemId(0));
        dispatch(mainSliceActions.setSort('RELEVANTFROMDATE'));
        dispatch(mainSliceActions.setSortedBy('RelevantFromDate'));
        dispatch(mainSliceActions.setSortedOrder());
        dispatch(mainSliceActions.setSearchInput(userInput));
        dispatch(mainSliceActions.setArticleTagId(undefined));
      
    } else if (e.key === 'ArrowDown') {
      setHighlightedIndex(prevIndex => (prevIndex + 1) % filteredSuggestions.length);
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex(prevIndex => (prevIndex === 0 ? filteredSuggestions.length - 1 : prevIndex - 1));
    }
  };

  const renderSuggestions = () => {
    if (userInput && filteredSuggestions.length > 0 && !isSelectedTag || isFocused) {
      return (
        <styles.DropdownContainer>
          <styles.DropdownList className="suggestions">
            {filteredSuggestions.slice(0, 10).map((suggestion, index) => (
              <styles.DropdownItem
                key={index}
                onClick={() => handleSelectSuggestion(suggestion)}
                style={{ backgroundColor: index === highlightedIndex ? '#E6F6FE' : 'inherit' }}
              >
                {suggestion}
              </styles.DropdownItem>
            ))}
          </styles.DropdownList>
        </styles.DropdownContainer>
      );
    } 
    return null;
  };

  return (
    <div>
      <styles.FilterWrapper>
        <div style={{ width: '272px' }}>
          <Select
            value={selectedOption}
            options={allSystems}
            placeholder={t('systemName')}
            isRtl={true}
            onChange={handleSelect as any}
            components={{ Option }}
            styles={customStyles}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <styles.SearchWrapper>
            <styles.CustomTextField
              label={t('search')}
              variant="outlined"
              value={userInput}
              ref={inputRef}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                setIsSelectedTag(false);
                renderSuggestions();
              }}
              onKeyDown={handleKeyDown}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchInput(e.target.value)}
              InputProps={{
                endAdornment: renderSuggestions(),
              }}
            />
            <SearchOutlinedIcon style={{ position: 'absolute', left: '5%', top: '22%', width: '15px', color: '#C4C4C4' }} />
          </styles.SearchWrapper>
          <styles.StyledSearchBtn onClick={handleSearch}>{t('searchBtn')}</styles.StyledSearchBtn>
        </div>
      </styles.FilterWrapper>
    </div>
  );
};

const customStyles = {
  //   option label
  option: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '16px',
    color: '#494949',
    BorderColor: '#B4BEC9',
    zIndex: 99,
  }),
  //   chosen label
  control: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: '16px',
    color: '#494949',
    BorderColor: '#B4BEC9',
    zIndex: 99,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Rubik',
    fontWeight: 400,
    color: '#C4C4C4',
    fontSize: '14px',
    BorderColor: '#B4BEC9',
    zIndex: 99,
  }),
};

const style = {
  title: {
    fontFamily: 'Rubik',
    fontWeight: 700,
    lineHeight: 2,
    fontSize: "36px",
    color: '#494949',
    marginBottom: '15px',
  },
};

export default Header;
