import styled from 'styled-components';
import { Typography } from '@mui/material';
import yitLogo from '../../images/yitLogo.png';


const Header = styled.div`
&& {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 20px 0px #00000029;
  font-weight: 400;
  color: #494949;
  border-top: 6px solid #2196F3;
  height: 5%;
}
`;
const Headliner = styled(Typography)`
  && {
    font-family: 'Rubik', sans-serif;
    color: #494949;
    font-weight: 500;
    font-size: 36px;
  }

    @media (max-height: 740px) {
    && {
      font-size: 34px; 
    }
    }
    @media (max-height: 600px) {
    && {
      font-size: 30px; 
    }
    }
    @media (max-height: 515px) {
    && {
      font-size: 26px; 
    }
    }
    @media (max-width: 539px) {
    && {
      font-size: 28px; 
    }
    }
`;
const LogoContainer = styled.div`
  background-image: url(${yitLogo});
  position: absolute;
  left: 1%;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 539px) {
    width: 25px;
    height: 25px;
  }

  @media (max-height: 550px) {
    width: 25px;
    height: 25px;
  }
`;
  // flex: 1;styled(Typography)`

const styles = {
    Header,
    Headliner,
    LogoContainer
};

export default styles;