import { useEffect } from 'react';
import { mainSliceActions, TagItem } from '../store/mainSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


export const useFetchTags = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchTags = async () => {
      dispatch(mainSliceActions.setIsLoading(true));
      try {
        const WS = '/GetArticlesTags';
        const URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_LOCAL_DEV_URL : process.env.REACT_APP_PROD_URL;
        
        const response = await axios.get(URL + WS);
        let data: TagItem[] = response.data.data;
        let setSuggestions = data?.map((tag) => tag.Name);
        
        dispatch(mainSliceActions.setSuggestions(setSuggestions));
        dispatch(mainSliceActions.setTags(data));
      } catch (error) {
        dispatch(mainSliceActions.setIsError(true));
      } finally {
        dispatch(mainSliceActions.setIsLoading(false));
      }
    };

    fetchTags();
  }, []);
};
