import { useEffect, useState, useRef } from 'react';
import { mainSliceActions, TableRow, ArticlePayload } from '../store/mainSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import axios from 'axios';
import qs from 'qs';
import { useResponsiveItemsPerPage } from './useResponsiveItemsPerPage ';

export const useFetchArticles = (payload: ArticlePayload) => {
  const dispatch = useAppDispatch();
  const { isSearch, sortedBy, sortedOrder,sort, offset, itemsPerPage, currentPage, currentId, articleTagId, tagsID, isPageChanging, currentArticle, lastFetchedPage } = useAppSelector(state => state.main);
  const [isOnMount, setIsOnMount] = useState(true);
  const [isMorePages, setIsMorePages] = useState(true);
  const [allPagesData, setAllPagesData] = useState<{ [key: number]: TableRow[] }>({});
  const [tableData, setTableData] = useState<TableRow[]>([]);
  let lastCallTimestamp = 0;

  const prevPageDataHasBeenSet = useRef(false);
  const rowsPerPage = useResponsiveItemsPerPage();

  const prevPayloadRef = useRef<ArticlePayload>(payload);

  useEffect(() => {
    const fetchArticles = async () => {
      dispatch(mainSliceActions.setIsLoading(true));
      
      try {
        const { row, MainClassificationID, sort, isAsc, searchKey, TagID } = payload;
        // console.log(row);

        const WS = '/GetArticles';
        const URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_LOCAL_DEV_URL : process.env.REACT_APP_PROD_URL;
        const formData = qs.stringify({
          row,
          maxRowInPage: 2 * rowsPerPage,
          MainClassificationID,
          sort,
          isAsc,
          searchKey: searchKey,
          TagID: tagsID
        });
        // console.log(tagsID);
        
        const response = await axios.post(URL + WS, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          }
        });        
        let data: TableRow[] = await response.data.data;
        setTableData(data);
        if(response.data.isSuccess && data.length === 0) {
          dispatch(mainSliceActions.setNoDataMsg(true));
        } else {
          dispatch(mainSliceActions.setNoDataMsg(false));
        }
        const hasNextPage = data?.length > rowsPerPage;
        const currentItems = hasNextPage ? data.slice(0, rowsPerPage) : data;
        setIsMorePages(hasNextPage);
        // setAllPagesData((prevData) => ({
        //   ...prevData,
        //   [currentPage]: currentItems,
        // }));
        console.log('hasNextPage ',hasNextPage, 'data ', data.length,'row per page ',rowsPerPage);
        

        // dispatch(mainSliceActions.setAllPagesData({ ...allPagesData, [currentPage]: currentItems }));

        dispatch(mainSliceActions.setData(data));
        dispatch(mainSliceActions.setHasMore(hasNextPage));
        dispatch(mainSliceActions.setPageCount(currentPage));
      } catch (error) {
        dispatch(mainSliceActions.setIsError(true));
      } finally {
        dispatch(mainSliceActions.setIsLoading(false));
        dispatch(mainSliceActions.setIsSearch(false));
        setIsOnMount(false);
      }
    };
      const isPayloadDifferent = JSON.stringify(prevPayloadRef.current) !== JSON.stringify(payload);
      // console.log('isPayloadDifferent ',isPayloadDifferent,'isOnMount ',isOnMount, 'isPayloadDifferent || isOnMount ',isPayloadDifferent || isOnMount);
      
    // prevPayloadRef.current = payload;
    // const timeUntilNextCall = Math.max(0, 5000 - (Date.now() - lastCallTimestamp));
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // lastCallTimestamp = Date.now() + timeUntilNextCall;
    // setTimeout(()=> {
    //   if(isPayloadDifferent || isOnMount) 
    fetchArticles();
    // }, timeUntilNextCall)
    // console.log(currentPage, offset, sortedBy, sort, sortedOrder, itemsPerPage, isSearch, currentId);
    
    
  }, [dispatch, currentPage, offset, sortedBy, sortedOrder, isSearch, currentId, articleTagId]);


  return {allPagesData, tableData, isMorePages};

};
