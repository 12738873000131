import React, { useEffect, useState } from 'react';
import styles from './style';

type Tags = {
    tags?: string[] | undefined;
  };
  
  const Tag: React.FC<Tags> = ({ tags }) => {
    const [displayedTags, setDisplayedTags] = useState<string[] | undefined>(tags);
    const [hiddenTags, setHiddenTags] = useState<string[] | undefined>([]);
    const [isHidden, setIsHidden] = useState(false);
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  
    let tagsLimit = 5;
  
    if (screenWidth > 1450) {
      tagsLimit = 15;
    } else if (screenWidth > 1050) {
      tagsLimit = 10;
    } else if (screenWidth > 550) {
      tagsLimit = 5;
    }
  
    useEffect(() => {
      const handleResize = () => {
        const newScreenWidth = window.innerWidth;
        setScreenWidth(newScreenWidth);
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
      if (tags && tags.length > tagsLimit) {
        const displayed = tags.slice(0, tagsLimit);
        const hiddenCount = `${tags.length - tagsLimit}+`;
        displayed.unshift(hiddenCount);
        setDisplayedTags(displayed);
        setHiddenTags(tags.slice(tagsLimit));
        setIsHidden(true);
      } else {
        setDisplayedTags(tags ?? []);
        setHiddenTags([]);
        setIsHidden(false);
      }
    }, [tags, tagsLimit]);


    return (
      <div>
        {Array.isArray(tags) && tags.length > 0 && (
          <styles.SingleTagContainer>
            {(displayedTags ?? []).map((tag, i) => {
              const isFirst = i === 0 && isHidden;
              const tooltipText = isFirst ? hiddenTags?.join(', ') : null;
              
              if (tag.length > 0) {
                return (
                  <styles.SingleTagItem
                    key={i}
                    $isFirst={isFirst}
                    $isHidden={isHidden}
                    data-tooltip={tooltipText || undefined}
                  >
                    {tag}
                  </styles.SingleTagItem>
                );
              }
            })}
          </styles.SingleTagContainer>
        )}
      </div>
    );
  };

export default Tag;
