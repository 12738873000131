import React from 'react';
import i18n from './i18n';
import Home from './screens/Home/Home';
import { useAppSelector } from './store/hooks';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    direction: 'rtl',
  });

const App: React.FC = () => {
    const language = useAppSelector(state => state.main.language);

    React.useEffect(() => {
        const dir = i18n.dir(language);
        document.documentElement.dir = dir;
        i18n.changeLanguage(language);
      }, [language]);

      
    return (
        <ThemeProvider theme={theme}>
            <Home />
        </ThemeProvider>
    );
};

export default App;
