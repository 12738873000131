import { useEffect } from 'react';
import { mainSliceActions, ClassificationItem } from '../store/mainSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


export const useFetchCategories = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchCategories = async () => {
      dispatch(mainSliceActions.setIsLoading(true));
      try {
        const WS = '/GetMainClassification';
        // const response = await axios.post('http://interfaceserviceapidev.yit.co.il/VersionDocumentationService/VersionDocumentationService.svc' + WS);
        const URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_LOCAL_DEV_URL : process.env.REACT_APP_PROD_URL;
        
        const response = await axios.post(URL + WS);
        // const response = await axios.get('./categories.json');
        let data: ClassificationItem[] = response.data.data;
        // console.log(data,URL);

        const systems = data.map(system => ({
            label: system.Name,
            value: system.Name,
            id: system.MainClassificationID,
            icon: `${system.Name}.png`
        }));
        const allOption = {
          label: t('allSystems'),
          value: t('allSystems'),
          id: 0,
          icon: ''
        };
        const updatedSystems = [allOption, ...systems];
        // console.log(updatedSystems);
        
        dispatch(mainSliceActions.setAllSystems(updatedSystems));
      } catch (error) {
        dispatch(mainSliceActions.setIsError(true));
      } finally {
        dispatch(mainSliceActions.setIsLoading(false));
      }
    };

    fetchCategories();
  }, []);
};
