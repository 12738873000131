import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { mainSliceActions } from '../store/mainSlice';

export const useResponsiveItemsPerPage = () => {
  const dispatch = useAppDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const { hasMore } = useAppSelector(state => state.main);

  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;
      
      let newRowsPerPage = rowsPerPage;

      if (hasMore) {
        if (screenHeight >= 1400) {
          newRowsPerPage = 16;
        } else if (screenHeight >= 1300) {
          newRowsPerPage = 14;
        } else if (screenHeight >= 1100) {
          newRowsPerPage = 12;
        } else if (screenHeight >= 950) {
          newRowsPerPage = 10;
        } else if (screenHeight >= 770) {
          newRowsPerPage = 8;
        }  else if (screenHeight >= 620) {
          newRowsPerPage = 6;
        } else if (screenHeight >= 490) {
          newRowsPerPage = 4;
        } else {
          newRowsPerPage = 2;
        }
      } else {
        // When on the last page, allow resizing to a smaller screen but not to a bigger screen
        if (screenHeight < 490 && rowsPerPage > 2) {
          newRowsPerPage = 2;
        } else if (screenHeight < 620 && rowsPerPage > 4) {
          newRowsPerPage = 4;
        } else if (screenHeight < 770 && rowsPerPage > 6) {
          newRowsPerPage = 4;
        } else if (screenHeight < 950 && rowsPerPage > 8) {
          newRowsPerPage = 6;
        } else if (screenHeight < 1100 && rowsPerPage > 10) {
          newRowsPerPage = 8;
        } else if (screenHeight < 1300 && rowsPerPage > 12) {
          newRowsPerPage = 12;
        } else if (screenHeight < 1400 && rowsPerPage > 14) {
          newRowsPerPage = 14;
        } else if (screenHeight >= 1400 && rowsPerPage > 16) {
          newRowsPerPage = 16;
        }
      }

      if (newRowsPerPage !== rowsPerPage) {
        setRowsPerPage(newRowsPerPage);
        dispatch(mainSliceActions.setMaxRows(newRowsPerPage));
        dispatch(mainSliceActions.updatePagination());
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [rowsPerPage, dispatch, hasMore]);

  return rowsPerPage;
};
