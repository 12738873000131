import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './index';

export type LangKeys = 'he' | 'en' | 'ru' | 'ar';

export interface TableRowBase {
    RelevantFromDate: string;
    MainClassificationName: string;
    ArticleHeader: string;
    Tags: string;
}
  
export interface TableRow extends TableRowBase {
    ArticleID: number;
}

export interface ArticlePayload {
    row: number;
    maxRowInPage: number;
    MainClassificationID: number;
    sort: string;
    isAsc: 0 | 1;
    searchKey: string;
    TagID: number | undefined | null;
}

export interface SearchPayload {
    row: number;
    maxRowInPage: number;
    MainClassificationID: number;
    sort: string;
    isAsc: 0 | 1;
    searchKey: string;
    TagID: number | undefined | null;
}

export interface ClassificationItem {
    MainClassificationID: number;
    Name: string;
}

export interface TagItem {
    TagID: number;
    Name: string;
}

export interface OptionType {
    label: string;
    value: string;
    id: number;
    icon: string;
}
type Order = 'up' | 'down';
type SortOrder =  0 | 1;

export interface MainState {
    allData: { [key: number]: TableRow[] };
    data: TableRow[];
    articles: TableRow[];
    currentItems: TableRow[];
    previousPageData: TableRow[];
    currentArticle: TableRow | null;
    hasMore: boolean;
    itemsPerPage: number;
    pageCount: number;
    offset: number;
    index: number;
    currentId: number;
    language: LangKeys;
    system: string;
    systemId: number;
    allSystems: OptionType[];
    tags: TagItem[];
    suggestions: string[];
    isModal: boolean;
    isLoading: boolean;
    isLoadingHtml: boolean;
    isError: boolean;
    isHtmlError: boolean;
    noDataMsg: boolean;
    noDataHtmlMsg: boolean;
    isSearch: boolean;
    currentPage: number;
    sortedBy: string;
    sort: string;
    sortedOrder: Order;
    isAsc: SortOrder;
    htmlContent: string;
    searchInput: string;
    isPageChanging: boolean;
    lastFetchedPage: number | null;
    allPagesData: TableRow[];
    articleTagId: number | undefined | null;
    maxRows: number;
    tagsID: string;
}

const initialState: MainState = {
    data: [],
    allData: [],
    articles: [],
    currentItems: [],
    previousPageData : [],
    currentArticle: null,
    hasMore: true,
    itemsPerPage: 8,
    pageCount: 0,
    offset: 0,
    index: 0,
    currentId: 1,
    language: 'he',
    system: 'all',
    systemId: 0,
    allSystems: [],
    tags: [],
    suggestions: [],
    isModal: false,
    isLoading: false,
    isLoadingHtml: false,
    isError: false,
    noDataMsg: false,
    isHtmlError: false,
    noDataHtmlMsg: false,
    isSearch: false,
    currentPage: 1,
    sortedBy: 'RelevantFromDate',
    sort: 'RELEVANTFROMDATE',
    sortedOrder: 'up' as Order,
    isAsc: 0 as SortOrder,
    htmlContent: ``,
    searchInput: '',
    isPageChanging: false,
    lastFetchedPage: null,
    allPagesData: [],
    articleTagId: null,
    maxRows: 8,
    tagsID: '',
}

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        // maxRows
        setMaxRows(state, action: PayloadAction<number>) {
            state.maxRows = action.payload;
        },
        setLastFetchedPage(state, action: PayloadAction<number | null>) {
            state.lastFetchedPage = action.payload;
        },
        setAllPagesData(state, action: PayloadAction<TableRow[]>) {
            state.allPagesData = action.payload;
        },
        setData(state, action: PayloadAction<TableRow[]>) {
            state.data = action.payload;
        },
        setAllData(state, action: PayloadAction<{ [key: number]: TableRow[] }>) {
            state.allData = {
                ...state.allData,
                ...action.payload,
              };
        },
        setIsPageChanging(state, action) {
            state.isPageChanging = action.payload;
        },
        setArticles(state, action: PayloadAction<TableRow[]>) {
            state.articles = action.payload;
        },
        setCurrentItems(state, action: PayloadAction<TableRow[]>) {
            state.currentItems = action.payload;
        },
        setPreviousPageData: (state, action: PayloadAction<TableRow[]>) => {
            if (state.currentPage > 1 && state.previousPageData.length === 0) { // Only set if on a new page and previousPageData is empty
              state.previousPageData = action.payload;
            }
          },
        setCurrentArticle(state, action: PayloadAction<TableRow | null>) {
            state.currentArticle = action.payload;
        },
        setHasMore(state, action: PayloadAction<boolean>) {
            state.hasMore = action.payload;
        },
        setOffset(state, action: PayloadAction<number>) {
            state.offset = action.payload;
        },
        setPageCount(state, action: PayloadAction<number>) {
            state.pageCount = action.payload;
        },
        setIndex(state, action: PayloadAction<number>) {
            state.index = action.payload;
        },
        setCurrentId(state, action: PayloadAction<number>) {
            state.currentId = action.payload;
            const current = state.data.find(item => item.ArticleID === state.currentId);
            if (current) {
                state.currentArticle = current;
            }
        },
        setSystemId(state, action: PayloadAction<number>) {
            state.systemId = action.payload;
        },
        setSystem(state, action: PayloadAction<string>) {
            state.system = action.payload;
        },
        setTags(state, action: PayloadAction<TagItem[]>) {
            state.tags = action.payload;
        },
        setTagsID(state, action: PayloadAction<string>) {
            state.tagsID = action.payload;
        },
        setSuggestions(state, action: PayloadAction<string[]>) {
            state.suggestions = action.payload;
        },
        setAllSystems(state, action: PayloadAction<{ value: string; label: string; id: number; icon: string; }[]>) {
            state.allSystems = action.payload; //tags
        },
        setIsModal(state, action: PayloadAction<boolean>) {
            state.isModal = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setIsLoadingHtml(state, action: PayloadAction<boolean>) {
            state.isLoadingHtml = action.payload;
        },
        setIsError(state, action: PayloadAction<boolean>) {
            state.isError = action.payload;
        },
        setIsHtmlError(state, action: PayloadAction<boolean>) {
            state.isHtmlError = action.payload;
        },
        setNoDataMsg(state, action: PayloadAction<boolean>) {
            state.noDataMsg = action.payload;
        },
        setNoDataHtmlMsg(state, action: PayloadAction<boolean>) {
            state.noDataHtmlMsg = action.payload;
        },
        setIsSearch(state, action: PayloadAction<boolean>) {
            state.isSearch = action.payload;
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.currentPage = action.payload;
        },
        setSortedBy(state, action: PayloadAction<string>) {
            state.sortedBy = action.payload;
        },
        setSort(state, action: PayloadAction<string>) {
            state.sort = action.payload;
        },
        setSortedOrder(state, action: PayloadAction<Order | undefined>) {
            const order = action.payload;
            if (order) {
              state.sortedOrder = order;
            } else {
              state.sortedOrder = state.sortedOrder === 'up' ? 'down' : 'up';
            }
        },
        setIsAsc(state, action: PayloadAction<SortOrder | undefined>) {
            const sortOrder = action.payload;
            if (sortOrder) {
              state.isAsc = sortOrder;
            } else {
              state.isAsc = state.sortedOrder === 'up' ? 0 : 1;
            }
        },
        setHtmlContent(state, action: PayloadAction<string>) {
            state.htmlContent = action.payload;
        },
        setSearchInput(state, action: PayloadAction<string>) {
            state.searchInput = action.payload;
        },
        setArticleTagId(state, action: PayloadAction<number | undefined | null>) {
            state.articleTagId = action.payload;
        },
        updatePagination: (state) => {
            const { currentPage, maxRows, data } = state;
            const startIndex = (currentPage - 1) * maxRows;
            const endIndex = Math.min(startIndex + maxRows, data.length);
            const currentItems = data.slice(0, maxRows);
            const pageCount = Math.ceil(data.length / maxRows);
            const allData = { ...state.allData, [currentPage]: currentItems };
        
            // console.log(`startIndex: ${startIndex}, endIndex: ${endIndex}`);
            // console.log(`currentItems: `, currentItems);
            return {
                ...state,
                currentItems: currentItems,
                pageCount: pageCount,
                offset: startIndex,
                allData: allData,
            };
        },
    },
  })

  export const mainSliceActions = mainSlice.actions;
  export const mainSliceSelector = (state: RootState): MainState => state.main;
export default mainSlice.reducer