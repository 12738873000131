import styled from 'styled-components';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(Dialog)`
.MuiPaper-root {
    max-height: 60vh;
    min-height: 85vh;
    height: 85vh;
    width: 85vw;
    overflow-y: initial;
}

  @media screen and (min-width: 3744px) {
    .MuiPaper-root {
      height: 80vh;
    }
  }

    @media screen and (min-height: 1848px) {
    .MuiPaper-root {
      height: 80vh;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
&& {
    width: 92%;
}
    &&.MuiDialogContent-root {
    overflow-x: hidden;
}
`;

const StyledActions = styled(DialogActions)`
  position: relative;
  width: 60px;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 10%;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
  overflow-y: hidden;
`;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
  overflow-y: hidden;
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
  overflow-y: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

const SystemImage = styled.img`
  width: 20px;
  border-radius: 50%;
  padding:0;
  margin: 0;
  vertical-align: middle;

    @media (max-height: 740px) {
    && {
      width: 12px;
    }
}
    @media (max-height: 530px) {
    && {
      width: 12px;
    }
}
    @media (max-width: 1000px) {
        &&{
      width: 20px;
    }
}
`;

const SystemText = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
`;

const DateText = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const VersionText = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

const StyledButton = styled(Button)`
&& {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
}
  &&:hover {
  background: white;
  }

`;

const VerticalLine = styled.div`
    border-left: 1px solid #909090;
    height: 50%;
    vertical-align: bottom;
`;

const ScrollViewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76%;
`;

const ScrollView = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(90vh - 160px);
  height: 95%;
  width: 95%;
  box-shadow: 0px 0px 20px 0px #0000001A;
  border-radius: 16px;

  /* Webkit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  /* Additional styling for compatibility */
  & {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  /* Additional styling for non-webkit browsers */
  &::-moz-scrollbar {
    width: 12px;
  }

  &::-moz-scrollbar-track {
    background: #f1f1f1;
  }

  &::-moz-scrollbar-thumb {
    background: #888;
    border-radius: 16px;
  }

  &::-moz-scrollbar-thumb:hover {
    background: #555;
  }
`;


const NextBtn = styled(Button)`
  && {
    background-color: #2196F3;
    color: white;
    border-radius: 4px;
    font-size: 18px;
    padding: 0;
    margin-bottom: 1%;
    height: 2.7rem;
    width: 11rem;
  }
    
  &&.MuiButton-root {
    padding-bottom: 0;
  }

  &&.MuiButton-root:hover {
    background-color: #1976D2;
  }

  &&.Mui-disabled {
    background-color: #c7c7c7;
    color: white;
  }


@media (min-height: 924px) {
  && {
  }
    &&.Mui-disabled{
    }
  }
    @media (min-width: 1872px) {
  && {
  }
        &&.Mui-disabled{
    }
  }

@media (max-height: 740px) {
  && {
        height: 2.1rem;
  }
        &&.Mui-disabled{
          height: 2.1rem;
    }
}

@media (max-height: 530px) {
  && {

  }
        &&.Mui-disabled{

    }
}

@media (max-width: 1550px) {
  && {
  }
      &&.Mui-disabled{
    }
}

@media (max-width: 1250px) {
  && {
  }
      &&.Mui-disabled{
    }
}

@media (max-width: 900px) {
  && {
  }
      &&.Mui-disabled{
    }
}
@media (max-width: 800px) {
  && {
  }
      &&.Mui-disabled{
    }
}
@media (max-width: 539px) {
  && {

  }
      &&.Mui-disabled{
    }
}
`;
// padding-left: 0px;
// padding-right: 4px;

// @media (min-height: 924px) {
//   && {
//     font-size: 18px;
//     height: 55%;
//     width: 12%;
//   }
//   }
//     @media (min-width: 1872px) {
//   && {
//     font-size: 18px;
//     height: 55%;
//     width: 12%;
//   }
//   }

// @media (max-height: 740px) {
//   && {
//     font-size: 12px;
//     width: 11%;
//   }
// }

// @media (max-height: 530px) {
//   && {
//     font-size: 7px;
//     width: 10%;
//   }
// }

// @media (max-width: 1550px) {
//   && {
//   height: 55%;
//   width: 11%;
//   font-size: 12px;
//   }
// }

// @media (max-width: 1250px) {
//   && {
//   height: 55%;
//   width: 14%;
//   font-size: 10px;
//   }
// }

// @media (max-width: 900px) {
//   && {
//   height: 54%;
//   width: 19%;
//   font-size: 13px;
//   }
// }
// @media (max-width: 800px) {
//   && {
//   height: 48%;
//   width: 23%;
//   font-size: 11px;
//   }
// }
// @media (max-width: 539px) {
//   && {
//   height: 39%;
//   width: 26%;
//   font-size: 9px;
//   }
// }
  // margin-left: 2%; height: fit-content;

  const PreviousBtn = styled(Button)`
  && {
    background-color: #2196F3;
    color: white;
    border-radius: 4px;
    font-size: 18px;
    padding: 0;
    margin-bottom: 1%;
    height: 2.7rem;
    width: 11rem;
  }

  &&.MuiButton-root {
    padding-bottom: 0;
  }

  &&.MuiButton-root:hover {
    background-color: #1976D2;
  }

  &&.Mui-disabled {
    background-color: #c7c7c7;
    color: white;
  }

@media (min-height: 924px) {
  && {
  }
        &&.Mui-disabled {
    }
  }
    @media (min-width: 1872px) {
  && {
  }
        &&.Mui-disabled {
    }
  }

@media (max-height: 740px) {
  && {
    height: 2.1rem;
  }

  &&.Mui-disabled {
    height: 2.1rem;
  }
}

@media (max-height: 530px) {
  && {
  }

  &&.Mui-disabled {
  }
}

@media (max-width: 1550px) {
  && {
  }
      &&.Mui-disabled {
    }
}

@media (max-width: 1250px) {
  && {
  }
      &&.Mui-disabled {
    }
}

@media (max-width: 900px) {
  && {
  }
      &&.Mui-disabled {
    }
}
@media (max-width: 800px) {
  && {
  }
      &&.Mui-disabled {
    }
}

@media (max-width: 539px) {
  && {
  }
      &&.Mui-disabled {
    }
}
`;
// padding-left: 4px;
// padding-right: 0px;

// @media (min-height: 924px) {
//   && {
//     font-size: 18px;
//     height: 55%;
//     width: 12%;
//   }
//   }
//     @media (min-width: 1872px) {
//   && {
//     font-size: 18px;
//     height: 55%;
//     width: 12%;
//   }
//   }

// @media (max-height: 740px) {
//   && {
//     font-size: 13px;
//     width: 11%;
//   }

//   &&.Mui-disabled {
//     font-size: 12px;
//     width: 11%;
//   }
// }

// @media (max-height: 530px) {
//   && {
//     font-size: 7px;
//     width: 10%;
//   }

//   &&.Mui-disabled {
//     font-size: 7px;
//     width: 10%;
//   }
// }

// @media (max-width: 1550px) {
//   && {
//   height: 55%;
//   width: 11%;
//   font-size: 12px;
//   }
// }

// @media (max-width: 1250px) {
//   && {
//   height: 55%;
//   width: 14%;
//   font-size: 10px;
//   }
// }

// @media (max-width: 900px) {
//   && {
//   height: 54%;
//   width: 19%;
//   font-size: 13px;
//   }
// }
// @media (max-width: 800px) {
//   && {
//   height: 48%;
//   width: 23%;
//   font-size: 11px;
//   }
// }

// @media (max-width: 539px) {
//   && {
//   height: 39%;
//   width: 26%;
//   font-size: 9px;
//   }
// }
  // margin-right: 2%;   height: fit-content;

  const StyledCloseIcon = styled(CloseIcon)`
  &.MuiSvgIcon-root {
    color: #494949;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-height: 740px) {
    &.MuiSvgIcon-root {
      width: 25px;
      height: 25px;
    }
  }

  @media (max-height: 530px) {
    &.MuiSvgIcon-root {
      width: 15px;
      height: 15px;
    }
  }

  @media (max-width: 539px) {
    &.MuiSvgIcon-root {
      width: 15px;
      height: 15px;
    }
  }
`;


const ArrowRight = styled(ArrowRightAltIcon)`
    margin-left: 8px;

    @media (max-height: 740px) {
      &.MuiSvgIcon-root {
        font-size: 1.2rem;
    }
    }
    @media (max-height: 530px) {
      &.MuiSvgIcon-root {
      margin-left: 5px;
      font-size: 1.5rem;
    }
    }
    @media (max-width: 1250px) {
    &.MuiSvgIcon-root {
        font-size: 1.2rem;
        margin-left: 6px;
    }
    }
    @media (max-width: 800px) {
    &.MuiSvgIcon-root {
        font-size: 1rem;
    }
    }
    @media (max-width: 539px) {
    &.MuiSvgIcon-root {
      font-size: 0.7rem;
      margin-left: 5px;
      font-size: 0.8rem;
    }
    }
    @media (max-width: 450px) {
    &.MuiSvgIcon-root {
      font-size: 0.7rem;
      margin-left: 5px;
      font-size: 0.8rem;
    }
    }
`;

const ArrowLeft = styled(ArrowRightAltIcon)`
    margin-right: 8px;
    transform: rotate(180deg);

    @media (max-height: 740px) {
      &.MuiSvgIcon-root {
        font-size: 1.2rem;
    }
    }
    @media (max-height: 530px) {
      &.MuiSvgIcon-root {
      margin-left: 5px;
      font-size: 1.5rem;
    }
    }
    @media (max-width: 1250px) {
    &.MuiSvgIcon-root {
        font-size: 1.2rem;
        margin-right: 6px;
    }
    }
    @media (max-width: 800px) {
    &.MuiSvgIcon-root {
      font-size: 1rem;
    }
    }
    @media (max-width: 539px) {
    &.MuiSvgIcon-root {
      margin-right: 5px;
      font-size: 0.8rem;
    }
    }
    @media (max-width: 450px) {
    &.MuiSvgIcon-root {
      font-size: 0.7rem;
      margin-right: 5px;
      font-size: 0.8rem;
    }
    }
`;

const HeaderTitle = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 30px;
    color: #494949;
    font-family: Rubik;
  }

  @media screen and (min-width: 3744px) {
    && {
      font-size: 45px;
    }
  }

    @media screen and (min-height: 1848px) {
    && {
      font-size: 45px;
    }
  }

    @media (max-height: 740px) {
    && {
      font-size: 21px;
    }
    }
    @media (max-height: 530px) {
    && {
      font-size: 19px;
    }
    }
    @media (max-width: 539px) {
    && {
      font-size: 19px;
    }
  }
`;
    // flex: 1;

const System = styled(Typography)`
    && {
      line-height: 2;
      font-size: 16px;
      color: #494949;
      font-weight: 400;
      font-family: Rubik;
    }

  @media screen and (min-width: 3744px) {
    && {
      font-size: 25px;
    }
  }
    @media screen and (min-height: 1848px) {
    && {
      font-size: 25px;
    }
  }
    @media (max-height: 740px) {
      && {
        font-size: 10px;
      }
    }
  
    @media (max-height: 530px) {
      && {
        font-size: 7px;
      }
    }
  
    @media (max-width: 639px) {
      && {
        font-size: 14px;
      }
    }
      @media (max-width: 550px) {
    && {
      font-size: 10px;
    }
  }
`;
  
  const Version = styled(Typography)`
  && {
    line-height: 2;
    font-size: 16px;
    color: #494949;
    font-weight: 400;
    font-family: Rubik;
  }

  @media screen and (min-width: 3744px) {
    && {
      font-size: 25px;
    }
  }

  @media screen and (min-height: 1848px) {
    && {
      font-size: 25px;
    }
  }

  @media (max-height: 740px) {
    && {
      font-size: 10px;
    }
  }

  @media (max-height: 530px) {
    && {
      font-size: 7px;
    }
  }

    @media (max-width: 639px) {
      && {
        font-size: 14px;
      }
    }
      @media (max-width: 550px) {
    && {
      font-size: 10px;
    }
  }
`;

const Date = styled(Typography)`
  && {
    line-height: 2;
    font-size: 16px;
    color: #494949;
    font-weight: 400;
    font-family: Rubik;
  }

  @media screen and (min-width: 3744px) {
    && {
      font-size: 25px;
    }
  }

  @media screen and (min-height: 1848px) {
    && {
      font-size: 25px;
    }
  }

  @media (max-height: 740px) {
    && {
      font-size: 10px;
    }
  }

  @media (max-height: 530px) {
    && {
      font-size: 7px;
    }
  }

    @media (max-width: 639px) {
      && {
        font-size: 14px;
      }
    }
      @media (max-width: 550px) {
    && {
      font-size: 10px;
    }
  }
`;


const SubHeader = styled.div`
  display: flex;
  gap: 20px;
  align-items: baseline;
  margin-bottom: 0.3rem;

  @media (max-height: 740px) {
    gap: 12px;
  }

  @media (max-height: 530px) {
    gap: 10px;
  }

  @media (max-width: 539px) {
    gap: 8px;
  }

  @media (max-width: 450px) {
    gap: 7px;
  }
`;

// margin-top: 10px;
// height: 10px;

const Header = styled(DialogTitle)`
&&.MuiDialogTitle-root {
  display: flex;
  justify-content: space-between;
  height: 14%;
  overflow: hidden;
  padding-bottom: 0;
}
`;

const Footer = styled(DialogTitle)`
&& {

}
  &&.MuiDialogTitle-root {
  display: flex;
  justify-content: space-between;
  margin-block-start: auto;
  padding: 0;
  width: 95%;
}
`;
  // margin-top: 0;

const Article = styled.div`
  font-weight: 400;
  font-family: Rubik;
  font-size: 14px;


    @media (max-height: 740px) {
    && {
      font-size: x-small;
    }
    }
    @media (max-height: 530px) {
    && {
      font-size: xx-small;
    }
    }
    @media (max-width: 539px) {
    && {
      font-size: xx-small;
    }
}
`;

const styles = {
    StyledDialog,
    HeaderContainer,
    StyledCloseIcon,
    SystemImage,
    SystemText,
    DateText,
    VersionText,
    LoadingContainer,
    NoDataContainer,
    ErrorContainer,
    FooterContainer,
    StyledButton,
    StyledDialogContent,
    StyledActions,
    ScrollViewWrapper,
    ScrollView,
    VerticalLine,
    NextBtn,
    PreviousBtn,
    ArrowLeft,
    ArrowRight,
    HeaderTitle,
    System,
    Version,
    SubHeader,
    Header,
    Footer,
    Date,
    Article,
};

export default styles;