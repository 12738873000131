import React from 'react';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import Footer from '../../components/Footer/Footer';
import Article from '../../components/Article/Article'
import { useFetchCategories } from '../../hooks/useFetchCategories';
import { useFetchTags } from '../../hooks/useFetchTags';

const Home: React.FC = () => {
    
    useFetchCategories();
    useFetchTags();

    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            margin: 0, 
            padding: 0,

            }}>
            <Header />
            <Table />
            <Article />
            <Footer />
        </div>
    );
};


export default Home;
